<template>
    <div>
        <Loading v-show="isLoading"/>
        
        <div v-show="!isLoading">

            <!-- Toolbar -->    
            <TitlePage 
                :title="'EDITAR CONSULTA'"
                :id="ticket.id"
                back="/support"
                :reset="false"
            />

            <div class="col-12 card mt-5">

                <p class="pl-2 pt-1 text-lg my-1 text-600 text-right">
                    <span class="font-semibold">CREADO EL </span>
                    {{ $moment.utc(ticket.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") }}
                </p>

                <!-- Timeline Comments -->
                <div class="grid mt-5">
                    <div class="col-12 p-3 shadow-2 my-2">
                        <span class="text-600">
                            <span class="font-semibold">MOTIVO: </span>
                            {{ticket.reason}}
                        </span>
                    </div>

                    <div class="col-12 p-3 shadow-2 my-2">
                        <span class="text-600">
                            <span class="font-semibold">DESCRIPCIÓN: </span>
                            <p class="text-justify">{{ticket.description}}</p>
                        </span>
                    </div>

                    <div class="col-12">
                        <h4 v-if="!ticket.comments.length" class="text-center text-500">ESTA CONSULTA NO POSEE COMENTARIOS ...</h4>
                        <Timeline v-if="ticket.comments.length" :value="ticket.comments" align="alternate" class="customized-timeline">
                            <template #content="slotProps">
                                <Card>
                                    <template #title>
                                        {{slotProps.item.user.last_name }} , {{slotProps.item.user.first_name }}
                                    </template>
                                    <template #subtitle>
                                        Creado el {{ $moment.utc(slotProps.item.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") }}
                                    </template>
                                    <template #content>
                                        <p class="text-justify">{{slotProps.item.comment}}</p>
                                    </template>
                                </Card>
                            </template>
                        </Timeline>
                    </div>
                </div>

                <form @submit.prevent="updateTicket">
                    <div class="grid mt-4">
                        <div class="col-12">
                            <Textarea 
                                v-model="form.comment" 
                                v-bind:disabled="!ticket.state"
                                placeholder="Descripción"
                                rows="5" 
                                class="inputfield w-full"/>
                        </div>

                        <div class="col-12">
                            <span class="p-buttonset">
                                <Button
                                    label="COMENTAR"
                                    tabindex="3"
                                    type="submit"
                                    v-bind:disabled="!ticket.state"
                                />
                                <Button
                                    label="FINALIZAR"
                                    @click="closeTicket()"
                                    tabindex="3"
                                    class="p-button-secondary"
                                    v-bind:disabled="!ticket.state"
                                />
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <MissingRecord v-if="missingRecord"/>

    </div>

</template>

<script>

import apiService from '../../services/apiService.js'

import Timeline from 'primevue/timeline';
import Card from 'primevue/card';

export default {
    components:{
        Timeline,
        Card
    },
    data(){
        return {
            isLoading: false,
            reasons: [],
            ticket: {
                status: '',
                reason: '',
                description: '',
                created_at: '',
                comments: [],
            },
            form: {
                id: '',
                comment: '',
            },
            missingRecord: false,
        }
    },
    mounted() {
        this.optReasons();
        this.getTicket();
    },
    methods: {
        async getTicket(){
            this.isLoading = true
            apiService.Assistance.get(this.$route.params.id)
            .then((result) => {
                this.form.id = result[0].id
                this.ticket.state = result[0].state
                this.ticket.reason = result[0].reason.name
                this.ticket.description = result[0].description
                this.ticket.created_at = result[0].created_at

                this.ticket.comments = result[0].comments;
                
                this.isLoading = false
            }).catch(() => {
                this.missingRecord = true
            })
        },
        async updateTicket(){
            apiService.Assistance.storeComment(this.form)
            .then((result) => {
                if(result.status == 'success'){
                    this.FormSuccess(result.message)
                    this.form.comment = "";
                    this.ticket.comments = result.comments
                }else{
                    this.FormErrors(result)
                }
            }).catch((e) => {
                this.FormRequestFail(e);
            })
        },
        async closeTicket(){
            this.$confirm.require({
                message: 'Finalizar la consulta actual?, recuerde que no podrá modificarla luego de esto.',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    apiService.Assistance.close(this.form.id)
                    .then((result) => {
                        if(result.status == 'success'){
                            this.FormSuccess(result.message)
                            this.form.comment = "";
                            this.ticket.comments = result.comments
                        }else{
                            this.FormErrors(result)
                        }
                    }).catch((e) => {
                        this.FormRequestFail(e);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        },
        async optReasons(){
            this.isLoading = true
            apiService.Assistance.reasons()
            .then((result) => {
                this.reasons = result
                this.isLoading = false
            })
        },
    }
}
</script>